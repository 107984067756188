var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"overflowX":"auto","w":"full","maxW":"100%"}},[_c('div',{staticClass:"head flex flex-col sm:flex-row justify-between items-center bg-orange-200 h-16 px-4"},[_c('c-box',{attrs:{"h":"40px","w":"full","maxW":"400px","mb":"2","sm:mb":"0"}},[_c('c-input-group',{attrs:{"bg":"red","display":"flex","color":"gray.600"}},[_c('c-input-left-element',[_c('c-icon',{attrs:{"ml":"0","name":"search","color":"gray.600"}})],1),_c('c-input',{staticStyle:{"background-color":"#faf8f7"},attrs:{"variant":"unstyled","color":"gray.600","type":"text","placeholder":"Search"}})],1)],1),_c('c-box',{attrs:{"display":"flex"}},[_c('c-icon-button',{attrs:{"color":"gray.600","variant-color":"transparent","icon":"ellipsis-h"}}),_c('c-icon-button',{attrs:{"color":"gray.600","variant-color":"transparent","icon":"arrow-down"}})],1)],1),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"grid__table"},[(_vm.hasHeader)?_c('thead',{staticClass:"grid__table__header"},[_c('tr',_vm._l((_vm.columns
              .filter((elm) => !elm.disabled)
              .map(({ title }) => title)),function(title){return _c('th',{key:title,staticClass:"grid__table__header__cell text-sm font-medium text-left px-4 bg-orange-50 text-gray-800"},[_vm._v(" "+_vm._s(title)+" ")])}),0)]):_vm._e(),_c('tbody',{staticClass:"grid__table__body"},_vm._l((_vm.data),function(cellData){return _c('tr',{key:cellData.id,staticClass:"grid__table__body__row"},_vm._l((_vm.columns.filter(
              (elm) => !elm.disabled
            )),function(column,columnIdx){return _c('td',{key:columnIdx,staticClass:"grid__table__body__cell text-sm text-left px-4 align-top h-16 border-t border-gray-200"},[(column.value === 'created_at')?_c('p',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.simplifyDate(cellData[column.value]))+" ")]):_c('p',{staticClass:"py-2",style:({
                color:
                  cellData[column.value] === 'ACTIVE' ? '#F05222' : '#0B2B47',
              })},[_vm._v(" "+_vm._s(cellData[column.value])+" ")])])}),0)}),0),(!_vm.disabled)?_c('tfoot',{staticClass:"grid__table__footer"}):_vm._e()])]),(_vm.isTeamDrawerOpen)?_c('TeamDrawer',{attrs:{"title":_vm.title,"type":_vm.type,"context":``,"isOpen":_vm.isTeamDrawerOpen},on:{"close":_vm.closeTeamDrawer}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }