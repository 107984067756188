<template>
  <div mx="5" class="container">
    <c-button
        @click="openFilterModal"
        variant-color="vc-orange"
        variant="outline"
        right-icon="filter"
        size="sm"
        position="absolute"
        top="18"
        right="20"
    >
      Filter
    </c-button>

    <!-- Filter Dialog -->
    <c-modal
        :is-open="isFilterModalOpen"
        @close="closeFilterModal"
        :closeOnOverlayClick="false"
        size="lg"
        is-centered
        class="rounded-lg"
    >

      <c-modal-content>
        <!-- Modal Header -->
        <c-modal-header>Filter Options</c-modal-header>
        <c-modal-close-button @click="closeFilter" variant-color="pink" />
        <c-modal-body>
          <!-- Filter Checklist -->
          <c-form-control>
            <c-form-label>Select Filters</c-form-label>
            <c-checkbox-group v-model="selectedFilters">
              <c-checkbox class="dark:bg-pink-600" value="name">Name</c-checkbox>
              <c-checkbox variant-color="red" value="date">Date Range</c-checkbox>
              <c-checkbox variant-color="red" value="location">Location</c-checkbox>
            </c-checkbox-group>
          </c-form-control>

          <c-divider my="6" v-if="selectedFilters.length !== 0" color="gray"></c-divider>

          <!-- Filter by Name -->
          <c-form-control my="4" v-if="selectedFilters.includes('name')">
            <c-form-label>Filter by Name</c-form-label>
            <c-input placeholder="Enter Company Name" v-model="filterOptions.name" />
          </c-form-control>

          <!-- Date Range Filter -->
          <c-form-control my="4" v-if="selectedFilters.includes('date')">
            <c-form-label>Filter by Date Range</c-form-label>
            <c-stack direction="row" spacing="4">
              <c-input type="date" v-model="filterOptions.startDate" />
              <c-input type="date" v-model="filterOptions.endDate" />
            </c-stack>
          </c-form-control>

          <!-- Filter by Location -->
          <c-form-control my="4" v-if="selectedFilters.includes('location')">
            <c-form-label>Filter by Location</c-form-label>
            <c-input placeholder="Enter Location" v-model="filterOptions.location" />
          </c-form-control>
        </c-modal-body>

        <c-divider mt="7" color="gray"></c-divider>

        <!-- Modal Footer -->
        <c-modal-footer>
          <c-button variant-color="vc-orange" @click="applyFilters" mr="3">
            Apply
          </c-button>
          <c-button @click="clearFilters" variant="outline">
            Clear
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <!-- Modal Body -->
    </c-modal>
    <div >
      <div >
        <c-heading as="h1">
          {{ criteria.title }}
        </c-heading>
        <c-text fontWeight="300" color="#495057" v-if="criteria.description">
          {{ criteria.description }}
        </c-text>
      </div>
    </div>

<!--    <c-box m="10">-->
<!--      <highcharts :options="chartOptions" />-->
<!--    </c-box>-->

    <data-table
        :has-header="true"
        :columns="tableColumns"
        :data="dashData"
    />
  </div>
</template>

<script>
// import { Chart } from 'highcharts-vue';
// import Highcharts from 'highcharts';
import DataTable from "@/views/Affiliate/components/DataTable.vue";
// import variablePieInit from "highcharts/modules/variable-pie";

// variablePieInit(Highcharts);
export default {
  name: "Index",
  components: {
    DataTable,
    // highcharts: Chart
  },

  methods: {
    openFilterModal() {
      this.isFilterModalOpen = true;
    },

    closeFilterModal() {
      this.isFilterModalOpen = false;
    },

    applyFilters() {
      this.closeFilterModal();
    },

    closeFilter() {
      // Close the filter modal
      this.closeFilterModal();
    },

    clearFilters() {
      this.selectedFilters = [];
      this.filterOptions = {
        name: '',
        startDate: '',
        endDate: '',
        location: '',
        // Clear more filter options based on your tableColumns
      };
    },
  },

  data() {
    return {
      isFilterModalOpen: false,
      selectedFilters: [], // To store selected filters
      filterOptions: {
        name: '',
        startDate: '',
        endDate: '',
        location: '',
      },
      criteria: {
        title: 'Statistics',
        description:
            "Get realtime statistics on your progress, here you get to see all the figures involved in how your doing with your affiliate work",
      },

      chartOptions: {
        chart: {
          type: 'pie',
          height: 500,
          width: 500,
          background: 'transparent',
          backgroundColor: 'transparent',
        },
        title: {
          text: 'Subscribtion Ratio',
          style: {
            fontFamily: 'Montserrat',
            fontSize: 18,
            fontWeight: 'bold'
          }
        },
        // tooltip: { enabled: false },
        plotOptions: {
          variablepie: {
            dataLabels: {
              enabled: false,
              style: {
                fontFamily: 'Arial, sans-serif', // Adjust the font family as needed
                fontSize: '12px', // Adjust the font size as needed
                fontWeight: 'normal', // Adjust the font weight as needed
              },
            },
          },
        },
        series: [
          {
            name: 'Categories',
            data: [
              { name: 'Freemium', y: 30, color: '#A3C4F3' },
              { name: 'Premium', y: 40, color: '#FFCFD2' },
              { name: 'Enterprise', y: 30, color: '#FDE4CF' },
            ],
          },
        ],
        exporting: { enabled: false },
      },

      tableColumns: [
        {
          action: () => null,
          component: null,
          title: 'Coupon',
          value: 'coupon',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Subscription',
          value: 'subscription',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Discount(%)',
          value: 'discount',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Commission(%)',
          value: 'commission',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Date',
          value: 'date',
          disabled: false,
        },
      ],

      dashData: [
        {
          id: 1,
          coupon: 'BBV6CJ005W',
          subscription: 'Freemium',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023',
        },
        {
          id: 2,
          coupon: 'BCV6CJ115W',
          subscription: 'Enterprise',
          discount: '4.9',
          commission: '27.5',
          date: '08/07/2023'
        },
        {
          id: 3,
          coupon: 'BCV6CJ115W',
          subscription: 'Premium',
          discount: '4.9',
          commission: '27.5',
          date: '08/07/2023',
        },
        {
          id: 4,
          coupon: 'BBV6CJ005W',
          subscription: 'Enterprise',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 5,
          coupon: 'BBV6CJ005W',
          subscription: 'Enterprise',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 6,
          coupon: 'BBV6CJ005W',
          subscription: 'Enterprise',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 7,
          coupon: 'BBV6CJ005W',
          subscription: 'Enterprise',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 8,
          coupon: 'BBV6CJ005W',
          subscription: 'Premium',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 9,
          coupon: 'BBV6CJ005W',
          subscription: 'Premium',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },
        {
          id: 10,
          coupon: 'BBV6CJ005W',
          subscription: 'Freemium',
          discount: '5.8',
          commission: '25.0',
          date: '06/06/2023'
        },

      ],
    }
  }
}
</script>

<style scoped>
.container{
  width: 85vw;
}

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>