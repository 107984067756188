<template>
  <c-box v-chakra overflowX="auto" w="full" maxW="100%">
    <div class="head flex flex-col sm:flex-row justify-between items-center bg-orange-200 h-16 px-4">
      <c-box h="40px" w="full" maxW="400px" mb="2" sm:mb="0">
        <c-input-group bg="red" display="flex" color="gray.600">
          <c-input-left-element>
            <c-icon ml="0" name="search" color="gray.600" />
          </c-input-left-element>
          <c-input style="background-color: #faf8f7" variant="unstyled" color="gray.600" type="text" placeholder="Search" />
        </c-input-group>
      </c-box>

      <c-box display="flex">
        <c-icon-button color="gray.600" variant-color="transparent" icon="ellipsis-h" />
        <c-icon-button color="gray.600" variant-color="transparent" icon="arrow-down" />
      </c-box>
    </div>
    <div class="table-container">
      <table class="grid__table">
        <thead v-if="hasHeader" class="grid__table__header">
        <tr>
          <th
              v-for="title in columns
                .filter((elm) => !elm.disabled)
                .map(({ title }) => title)"
              :key="title"
              class="grid__table__header__cell text-sm font-medium text-left px-4 bg-orange-50 text-gray-800"
          >
            {{ title }}
          </th>
        </tr>
        </thead>
        <tbody class="grid__table__body">
        <tr
            v-for="(cellData) of data"
            :key="cellData.id"
            class="grid__table__body__row"
        >
          <td
              :key="columnIdx"
              v-for="(column, columnIdx) in columns.filter(
                (elm) => !elm.disabled
              )"
              class="grid__table__body__cell text-sm text-left px-4 align-top h-16 border-t border-gray-200"
          >
            <p
                v-if="column.value === 'created_at'"
                class="py-2"
            >
              {{ simplifyDate(cellData[column.value]) }}
            </p>
            <p
                v-else
                :style="{
                  color:
                    cellData[column.value] === 'ACTIVE' ? '#F05222' : '#0B2B47',
                }"
                class="py-2"
            >
              {{ cellData[column.value] }}
            </p>
          </td>
        </tr>
        </tbody>
        <tfoot class="grid__table__footer" v-if="!disabled">
        </tfoot>
      </table>
    </div>
    <TeamDrawer
        :title="title"
        :type="type"
        :context="``"
        :isOpen="isTeamDrawerOpen"
        @close="closeTeamDrawer"
        v-if="isTeamDrawerOpen"
    />
  </c-box>
</template>

<script>
import TeamDrawer from '@/views/App/components/OpenAiDrawer.vue';
export default {
  // props: [
  //   'columns',
  //   'hasHeader',
  //   'onAddRow',
  //   'onRemoveRow',
  //   'addRowLabel',
  //   'disabled',
  // ],
  components: {
    TeamDrawer,
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    onAddRow: {
      type: Function,
    },
    onRemoveRow: {
      type: Function,
    },
    addRowLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    hideAIGenerate() {
      return this.title === 'Costs' || this.title === 'Revenue';
    },
    isStaging() {
      return (
          window.location.hostname.startsWith('develop') ||
          window.location.hostname.startsWith('localhost')
      );
    },
  },
  data() {
    return {
      isTeamDrawerOpen: false,
      testcolumns: [
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employeeResponsible',
          disabled: true,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'actionPlan',
          disabled: false,
        },
      ],
      tableData: [
        { actionPlan: 'monitor', employeeResponsible: 'emmanuel' },
        { actionPlan: 'monitor', employeeResponsible: 'emmanuel' },
      ],
    };
  },
  methods: {
    simplifyDate(_date) {
      return new Date(_date).toISOString().split('T')[0]
    },
    closeTeamDrawer(results) {
      this.onAddRow(results);
      this.isTeamDrawerOpen = false;
    },
    openAiDrawer() {
      this.onAddRow();
      this.isTeamDrawerOpen = true;
    },
  },
  // computed: {
  //   selectedSegmentComponent() {
  //     return this.segments.find(
  //       (segment) => segment.value === this.selectedSegment
  //     ).component;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f05028;
  height: 70px;
  width: available;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.grid__table {
  @apply w-full;
  &__header {
    background-color: #FFF6F2;
    &__cell {
      @apply text-sm font-medium text-left px-4;
      color: #2d2d2d;
      min-width: 180px;
      max-width: 250px;
      height: 55px;
      font-weight: bold;
      border-width: 1px 1px 0 0;
      border-color: rgba(112, 112, 112, 0.2);
    }
  }
  &__body {
    background-color: #FFF6F2;
    &__cell {
      @apply text-sm text-left px-4 align-top;
      height: 45px;
      align-self: center;
      border-width: 1px 1px 0 0;
      border-color: rgba(112, 112, 112, 0.2);

      p {
        padding-top: 10px;
      }
    }
  }
  &__footer {
    &__cell {
      height: 65px;
      border-width: 1px 1px 1px 0;
      border-color: rgba(112, 112, 112, 0.2);
    }
  }
}
</style>
